import {AdvancedVideo, AdvancedImage} from '@cloudinary/react';
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
  
  function ShortVideos(props) {

      const [loading, setLoading] = useState(true);

      useEffect(() => {
        setTimeout(() => {
          setLoading(false)
        }, 300)
      }, [])


      return (

      <div className="container-fluid-lg bgDark min-vh-100">
    
        { loading ? 
      
          <div className="spinner-wrapper">
            <AdvancedImage className='img-fluid' cldImg={props.loadViz} />
            <span className="visually-hidden">Loading...</span>
            </div> 
            :
    
    
    <div className="container-fluid mainBg min-vh-100">

<div className="row g-3">
            
            <motion.div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 center-text-div p-5"
                          variants={props.vVariants}
                          initial='hidden'
                          whileInView='show'>
                <div className="card border border-light border-2">
                <AdvancedVideo className='img-fluid card-img-top autoplay' cldVid={props.v3Full} controls />
                            <div className="card-body videocard border border-dark border-4">
                              <p className="card-text text-white">"The Vote" (Teaser)</p>
                            </div>
                </div>
              </motion.div>
  
            <motion.div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 center-text-div p-5"
                          variants={props.vVariants}
                          initial='hidden'
                          whileInView='show'>
                <div className="card border border-light border-2 wrapper">         
                <iframe className='img-fluid' src="https://www.youtube.com/embed/zkZ7RmQGTS8?si=F4ka9G9WRTamC0EZ" frameborder="0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            <div className="card-body videocard border border-dark border-4">
                              <p className="card-text text-white">"The Vote" (Long Form)</p>
                            </div>
                </div>
            </motion.div>
  
            </div>

          <div className="row g-3"> 
            <motion.div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 center-text-div p-5"
                        variants={props.vVariants}
                        initial='hidden'
                        whileInView='show'>
              <div className="card border border-light border-2">
              <AdvancedVideo className='img-fluid card-img-top autoplay' cldVid={props.v4Full} controls />
              <div className="card-body videocard border border-dark border-4">
                            <p className="card-text text-white ">"For the winners"</p>
                          </div>    
              </div>
            </motion.div>

            <motion.div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 center-text-div p-5"
                        variants={props.vVariants}
                        initial='hidden'
                        whileInView='show'>
              <div className="card border border-light border-2">
              <AdvancedVideo className='img-fluid card-img-top autoplay' cldVid={props.v2Full} controls />
                          <div className="card-body videocard border border-dark border-4">
                            <p className="card-text text-white">"Sensory bop"</p>
                          </div>
              </div>
          </motion.div>

          </div>

        </div>}

      </div>
     
      );
    }
    
    export default ShortVideos;