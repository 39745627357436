import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import Footer from "../Footer";
import NavBar from "../NavBar";
import SubmitAlert from "./SubmitAlert";
import WhiteBreak from '../Spacers/WhiteBreak';



function ContactPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [company, setCompany] = useState('');
  const [success, setSuccess] = useState(null);
  const [failed, setFailed] = useState(null);


  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      from_company: company,
      to_name: 'Manon',
      message: message,
    };

    emailjs
    .send(process.env.REACT_APP_S_ID_API_KEY, 
          process.env.REACT_APP_T_ID_API_KEY, 
          templateParams, 
          process.env.REACT_APP_P_API_KEY) 
    .then(
      () => {
        console.log('SUCCESS!');
        setName('');
        setEmail('');
        setCompany('');
        setMessage('');
        setSuccess(true);
      },
      (error) => {
        console.log('FAILED...', error.text, error);
        setFailed(true);
      },
    );
};




    return (
   
 
      <div className="container-fluid min-vh-100 contactForm center-text-div">

      <NavBar />

          <div className='row'>

                  <div className='col-sm-12 col-md-12 col-lg-6 min-vh-100  center-text-div'>             
                    <div className="container card" 
                         style={{
                              backgroundColor: 'transparent',
                              border: '5px solid',
                              color: 'white', 
                              borderColor: 'rgb(176, 184, 249)'}}>

                          <div className="card-body p-5">

                          <h1 className="card-text mb-2 mainFont" style={{fontSize: '7vw'}}>Let's collab.</h1>
                          <h4 className="card-text mb-2 mainFont">Email: hello.sfmersive@gmail.com</h4>
                          </div>
                    </div>
                  </div>



                  <div className='col-sm-12 col-md-12 col-lg-6 contact-bg min-vh-100  center-text-div'>
                      <form className="row g-3 p-3" onSubmit={sendEmail}>

                          <div className="col-md-6">
                              <input type="text" 
                                     name="user_name" 
                                     className="contactForm-name form-control"
                                     placeholder="Name"
                                     value={name}
                                     style={{ border: '3px solid', background: 'transparent'}}
                                     onChange={(e) => setName(e.target.value)} />
                          </div>

                          
                          <div className="col-md-6">
                              <input type="email" 
                                     name="user_email" 
                                     className="contactForm-email form-control"
                                     placeholder="Email"
                                     value={email}
                                     style={{ border: '3px solid', background: 'transparent'}}
                                     onChange={(e) => setEmail(e.target.value)}/>
                          </div>


                          <div className="col-md-12">

                              <input type="text" 
                                     name="user_company" 
                                     className="form-control"
                                     placeholder="Organization"
                                     value={company}
                                     style={{ border: '3px solid', background: 'transparent'}}
                                     onChange={(e) => setCompany(e.target.value)}/>
                            </div>


                          <div className="col-12">
                              <textarea type="textarea"
                                      name="message" 
                                      className="contactForm-mess form-control"
                                      placeholder="Message"
                                      value={message}
                                      style={{ border: '3px solid', background: 'transparent'}}
                                      onChange={(e) => setMessage(e.target.value)} />
                              </div>


                          <div className="col-12">
                              <button type="submit"
                                      className='btn btn-outline-dark btn-sm border-3 rounded-pill'
                                      value="Send"
                                      sx={ { color: 'black' }}>
                                      Send
                              </button>

                              <br/>
                              <br/>

                          </div>
                      </form>

            <SubmitAlert success={success} failed={failed}/>

                  </div>

                </div>
          
                <WhiteBreak />
              
            <Footer />
      </div>

    );
  }
  
  export default ContactPage;